<div class="onboarding_content">
<div class="onboarding_application">
  <h1 class="header">Name your app</h1>
  <div class="text">
    A Squid application connects your frontend to all the data, APIs, and backend resources you’ll need to build your application. Click next to continue or feel free to change the name below
  </div>

  <create-application-form (formSubmitted)="submitCreateApplication()"></create-application-form>
  <button class="primary_button extra_large_button next_button" mat-flat-button type="submit"
          (click)="submitCreateApplication()"
          [disabled]="!createApplicationFormComponent?.form?.valid || submitCreateApplicationInProgress"
          data-testid="onboarding-next-button"
  >
    <loading-indicator-overlay [isLoading]="submitCreateApplicationInProgress">Next</loading-indicator-overlay>
  </button>
</div>
</div>


