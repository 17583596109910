import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AiTestChatFlyOutService, ChatMessage } from './ai-test-chat-fly-out.service';

@Component({
  selector: 'ai-test-chat-fly-out',
  templateUrl: './ai-test-chat-fly-out.component.html',
  styleUrls: ['./ai-test-chat-fly-out.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiTestChatFlyOutComponent {
  historyEnabled = true;

  constructor(protected readonly aiTestChatFlyOutService: AiTestChatFlyOutService) {}

  onChange(event: Event): void {
    const history = (event as Event & { detail: { history: Array<ChatMessage> } }).detail.history;
    this.aiTestChatFlyOutService.setHistory(history || []);
  }
}
