import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';

export async function copyText(text: string): Promise<void> {
  document.body.focus();
  await navigator.clipboard.writeText(text);
}

export async function copy(value: string, what?: string, snackBar?: SnackBarService): Promise<void> {
  await copyText(value);
  if (snackBar) {
    snackBar.success(`${what || 'Text'} copied to clipboard`);
  }
}
