import { IntegrationType } from '@squidcloud/client';
import { IntegrationCategory } from '@squidcloud/console-common/types/integration.types';

export type IntegrationTab =
  | 'details'
  | 'schema'
  | 'readonly-schema'
  | 'metrics'
  | 'profiles'
  | 'show-api-script'
  | 'db-browser'
  | 'how-to-use'
  | 'audit';

const BasicTabs: Array<IntegrationTab> = ['details', 'show-api-script'];
const BasicMetricsTabs: Array<IntegrationTab> = ['details', 'metrics', 'show-api-script'];
const DbSchemaTabs: Array<IntegrationTab> = ['details', 'schema', 'db-browser', 'metrics', 'show-api-script'];
const ReadonlySchemaTabs: Array<IntegrationTab> = ['details', 'readonly-schema', 'metrics', 'show-api-script'];

export const Integrations: Record<IntegrationType, Omit<IntegrationContent, 'type'>> = {
  [IntegrationType.ai_agents]: {
    name: 'AI Agents',
    description: 'Build your own AI agents with custom instructions and persistent contexts.',
    category: IntegrationCategory.ai,
    available: true,
    tabs: ['profiles', 'how-to-use', 'audit'],
  },
  [IntegrationType.built_in_db]: {
    name: 'Built-In DB',
    description: "Squid's flexible and scalable document-orientated database",
    category: IntegrationCategory.database,
    available: true,
    tabs: ['schema', 'db-browser', 'metrics'],
  },
  [IntegrationType.built_in_queue]: {
    name: 'Built-In Queue',
    description: "Squid's flexible and scalable queue service. Provides topics, pub/sub, and more.",
    category: IntegrationCategory.queue,
    available: true,
    tabs: ['metrics'],
  },
  [IntegrationType.kafka]: {
    name: 'Kafka',
    description: 'Flexible and scalable queue service',
    category: IntegrationCategory.queue,
    available: true,
    tabs: ['details', 'show-api-script'],
  },
  [IntegrationType.confluent]: {
    name: 'Confluent Kafka',
    description: 'Flexible and scalable queue service powered by Confluent',
    category: IntegrationCategory.queue,
    available: true,
    tabs: ['details', 'show-api-script'],
  },
  [IntegrationType.built_in_s3]: {
    name: 'AWS S3',
    description: 'Allow Squid to store, delete and fetch files from an AWS S3 bucket.',
    category: IntegrationCategory.storage,
    available: true,
    tabs: ['metrics'],
  },
  [IntegrationType.built_in_gcs]: {
    name: 'Google Cloud Storage',
    description: 'Allow Squid to store, delete and fetch files from an Google Cloud Storage bucket.',
    category: IntegrationCategory.storage,
    available: true,
    tabs: ['metrics'],
  },
  [IntegrationType.google_drive]: {
    name: 'Google Drive',
    description: "Allow Squid to interact with user's Google Drive.",
    category: IntegrationCategory.storage,
    available: true,
    tabs: ['metrics', 'details'],
  },
  [IntegrationType.s3]: {
    name: 'AWS S3',
    description: 'Allow Squid to store, delete and fetch files from an AWS S3 bucket.',
    category: IntegrationCategory.storage,
    available: true,
    tabs: ['metrics'],
  },
  [IntegrationType.gcs]: {
    name: 'Google Cloud Storage',
    description: 'Allow Squid to store, delete and fetch files from an Google Cloud Storage bucket.',
    category: IntegrationCategory.storage,
    available: true,
    tabs: ['metrics'],
  },
  [IntegrationType.mysql]: {
    name: 'MySQL',
    description: 'Reliable and user-friendly open-source database',
    category: IntegrationCategory.database,
    available: true,
    tabs: DbSchemaTabs,
  },
  [IntegrationType.clickhouse]: {
    name: 'ClickHouse',
    description:
      'High-performance, open-source columnar database management system optimized for fast real-time analytics',
    category: IntegrationCategory.database,
    available: true,
    tabs: DbSchemaTabs,
  },
  [IntegrationType.bigquery]: {
    name: 'BigQuery',
    description:
      "Highly scalable and serverless data warehouse designed for super-fast SQL queries using the processing power of Google's infrastructure",
    category: IntegrationCategory.database,
    available: true,
    tabs: DbSchemaTabs,
  },
  [IntegrationType.oracledb]: {
    name: 'Oracle',
    description: 'Reliable and scalable enterprise relational database with strong security features',
    category: IntegrationCategory.database,
    available: true,
    tabs: DbSchemaTabs,
  },
  [IntegrationType.mssql]: {
    name: 'Microsoft SQL Server',
    description: 'Enterprise relational database with strong security features',
    category: IntegrationCategory.database,
    available: true,
    tabs: DbSchemaTabs,
  },
  [IntegrationType.mongo]: {
    name: 'MongoDB',
    description: 'Flexible and scalable document-oriented NoSQL database',
    category: IntegrationCategory.database,
    available: true,
    tabs: DbSchemaTabs,
  },
  [IntegrationType.postgres]: {
    name: 'PostgreSQL',
    description: 'Advanced open-source object-relational database management system',
    category: IntegrationCategory.database,
    available: true,
    tabs: DbSchemaTabs,
  },
  [IntegrationType.cockroach]: {
    name: 'CockroachDB',
    description: 'Highly distributed, resilient, and horizontally scalable SQL database',
    category: IntegrationCategory.database,
    available: true,
    tabs: DbSchemaTabs,
  },
  [IntegrationType.graphql]: {
    name: 'GraphQL',
    description: 'Efficient and flexible API communication using GraphQL',
    category: IntegrationCategory.api,
    available: true,
    tabs: ReadonlySchemaTabs,
  },
  [IntegrationType.api]: {
    name: 'API',
    description: 'Build and consume interoperable RESTful API',
    category: IntegrationCategory.api,
    available: true,
    tabs: ['details', 'schema', 'metrics', 'show-api-script'],
    hideTestConnection: true,
  },
  [IntegrationType.datadog]: {
    name: 'Datadog',
    description: 'Connect your own Datadog account to consume metrics and logs',
    category: IntegrationCategory.observability,
    available: true,
    tabs: BasicMetricsTabs,
    hideTestConnection: true,
  },
  [IntegrationType.newrelic]: {
    name: 'NewRelic',
    description: 'Connect your own NewRelic account to consume metrics and logs',
    category: IntegrationCategory.observability,
    available: true,
    tabs: BasicMetricsTabs,
    hideTestConnection: true,
  },
  [IntegrationType.snowflake]: {
    name: 'Snowflake',
    description: 'Cloud data warehouse for storing, processing and analyzing big data with SQL',
    category: IntegrationCategory.database,
    available: true,
    tabs: DbSchemaTabs,
  },
  [IntegrationType.auth0]: {
    name: 'Auth0',
    description: 'Provide robust authentication and authorization services through a flexible, drop-in solution',
    category: IntegrationCategory.auth,
    available: true,
    tabs: BasicTabs,
    hideTestConnection: true,
  },
  [IntegrationType.jwt_rsa]: {
    name: 'JWT RSA Auth',
    description: 'Leverage JSON Web Tokens (JWTs) with RSA encryption for secure, asymmetric key authentication',
    category: IntegrationCategory.auth,
    available: true,
    tabs: BasicTabs,
    hideTestConnection: true,
  },
  [IntegrationType.ai_chatbot]: {
    name: 'AI Chatbot',
    description: 'Build your own AI agent with custom instructions and persistent contexts.',
    category: IntegrationCategory.ai,
    available: true,
    tabs: ['details', 'profiles', 'how-to-use'],
    hideTestConnection: true,
    deprecated: true,
  },
  [IntegrationType.cognito]: {
    name: 'Cognito',
    description: "Securely manage and authenticate your users with AWS' scalable and customizable user service.",
    category: IntegrationCategory.auth,
    available: true,
    tabs: BasicTabs,
    hideTestConnection: true,
  },
  [IntegrationType.okta]: {
    name: 'Okta',
    description: "Manage and authenticate your users with Okta's robust and flexible identity platform.",
    category: IntegrationCategory.auth,
    available: true,
    tabs: BasicTabs,
    hideTestConnection: true,
  },
  [IntegrationType.descope]: {
    name: 'Descope',
    description: "Manage and authenticate your users with Descope's robust and flexible identity platform.",
    category: IntegrationCategory.auth,
    available: true,
    tabs: BasicTabs,
    hideTestConnection: true,
  },
  [IntegrationType.firebase_auth]: {
    name: 'Firebase Auth',
    description: "Manage and authenticate your users with Firebase's robust and flexible identity platform.",
    category: IntegrationCategory.auth,
    available: true,
    tabs: BasicTabs,
    hideTestConnection: true,
  },
  // Coming Soon
  [IntegrationType.jwt_hmac]: {
    name: 'JWT HMAC Auth',
    description: 'Leverage JSON Web Tokens (JWTs) with HMAC encryption for secure, symmetric key authentication',
    category: IntegrationCategory.auth,
    available: false,
    tabs: BasicTabs,
    hideTestConnection: true,
  },
  [IntegrationType.alloydb]: {
    name: 'Google Cloud AlloyDB',
    description: 'A fully-managed, PostgreSQL-compatible, relational database service.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.algolia]: {
    name: 'Algolia',
    description: 'Fast, reliable, and customizable search and discovery platform.',
    category: IntegrationCategory.api,
    available: false,
  },
  [IntegrationType.azure_sql]: {
    name: 'Microsoft Azure SQL',
    description: 'Scalable and secure cloud database for storage and processing of relational data.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.azure_postgresql]: {
    name: 'Microsoft Azure PostgreSQL',
    description: 'Managed cloud service based on the PostgreSQL database engine.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.azure_cosmosdb]: {
    name: 'Microsoft Azure Cosmos DB',
    description: 'Multi-model, globally distributed database for large-scale applications.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.cassandra]: {
    name: 'Cassandra',
    description: 'Scalable, distributed NoSQL database for high availability and large-scale data management.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.db2]: {
    name: 'IBM DB2',
    description: 'High-performance, scalable relational database management system.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.documentdb]: {
    name: 'Amazon DocumentDB',
    description: 'Fully managed non-relational database service compatible with MongoDB.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.dynamodb]: {
    name: 'Amazon DynamoDB',
    description: 'Fully managed NoSQL database services, with low latency and high availability.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.elastic_enterprise_search]: {
    name: 'Elastic Enterprise Search',
    description: 'Powerful search solution for enterprises, with intuitive UI and support for various data sources.',
    category: IntegrationCategory.api,
    available: false,
  },
  [IntegrationType.elastic_observability]: {
    name: 'Elastic Observability',
    description: 'Real-time monitoring and analysis of distributed systems, applications, and infrastructure.',
    category: IntegrationCategory.observability,
    available: false,
  },
  [IntegrationType.elastic_search]: {
    name: 'Elasticsearch',
    description: 'Open source search engine for fast, scalable and distributed full-text search.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.mariadb]: {
    name: 'MariaDB',
    description: 'Open-source relational database management system.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.redis]: {
    name: 'Redis',
    description: 'In-memory data structure store, used as a database, cache, and message broker.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.salesforce_crm]: {
    name: 'Salesforce CRM',
    description: 'A cloud-based platform for managing customer relationships, sales, and marketing.',
    category: IntegrationCategory.crm,
    available: false,
  },
  [IntegrationType.sap_hana]: {
    name: 'SAP HANA',
    description: 'In-memory database platform for real-time analytics and applications.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.sentry]: {
    name: 'Sentry',
    description: 'Comprehensive solution for real-time error tracking and debugging.',
    category: IntegrationCategory.observability,
    available: false,
  },
  [IntegrationType.spanner]: {
    name: 'Google Cloud Spanner',
    description: 'Distributed, horizontally scalable relational database service.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.xata]: {
    name: 'Xata',
    description: 'Scalable, type-safe database with branches and a search-engine built right in.',
    category: IntegrationCategory.api,
    available: false,
  },
  [IntegrationType.firestore]: {
    name: 'Google Cloud Firestore',
    description:
      'Flexible, scalable NoSQL cloud database to store and sync data for client and server-side development.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.cloudsql]: {
    name: 'Google Cloud CloudSQL',
    description: 'Fully-managed relational database service for MySQL, PostgreSQL, and SQL Server.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.slack]: {
    name: 'Slack',
    description: 'Messaging app for teams, with chat, video, and voice features.',
    category: IntegrationCategory.saas,
    available: false,
  },
  [IntegrationType.hubspot]: {
    name: 'HubSpot',
    description: 'Marketing and sales platform helping businesses manage and analyze customer interactions and data.',
    category: IntegrationCategory.saas,
    available: false,
  },
  [IntegrationType.monday]: {
    name: 'Monday',
    description: 'Collaboration and productivity tool that allows teams to manage projects, workflows, and tasks.',
    category: IntegrationCategory.saas,
    available: false,
  },
  [IntegrationType.google_docs]: {
    name: 'Google Docs',
    description: 'Web-based word processing app that allows users to collaborate on documents in real-time.',
    category: IntegrationCategory.saas,
    available: false,
  },
  [IntegrationType.onedrive]: {
    name: 'Microsoft OneDrive',
    description: 'Cloud-based file storage and synchronization service.',
    category: IntegrationCategory.saas,
    available: false,
  },
  [IntegrationType.zendesk]: {
    name: 'Zendesk',
    description:
      'Customer service platform that provides tools for managing support tickets and customer interactions.',
    category: IntegrationCategory.saas,
    available: false,
  },
  [IntegrationType.linear]: {
    name: 'Linear',
    description: 'Project management tool for teams to plan, manage, and track their work efficiently.',
    category: IntegrationCategory.saas,
    docs: 'https://docs.getsquid.ai/docs/connectors/linear',
    available: true,
    tabs: ReadonlySchemaTabs,
  },
  [IntegrationType.pinecone]: {
    name: 'Pinecone',
    description:
      'Vector database that enables developers to store, search, and manage vector embeddings for ML/AI applications.',
    category: IntegrationCategory.database,
    available: false,
  },
  [IntegrationType.servicenow]: {
    name: 'ServiceNow',
    description:
      'Cloud-based platform that provides digital operations workflows for automating processes and improving efficiency.',
    category: IntegrationCategory.saas,
    available: true,
    tabs: ['details'],
    hideTestConnection: true,
  },
  [IntegrationType.jira]: {
    name: 'Jira',
    description:
      'Project management tool designed for teams to plan, track, and manage issues, tasks, bugs, and agile workflows.',
    category: IntegrationCategory.saas,
    available: false,
  },
  [IntegrationType.confluence]: {
    name: 'Confluence',
    description:
      'Collaborative knowledge management workspace for creating and organizing documents, wikis, and project information.',
    category: IntegrationCategory.saas,
    tabs: ['details', 'schema'],
    hideTestConnection: true,
    available: true,
  },
  [IntegrationType.active_directory]: {
    name: 'Active Directory',
    description:
      'Microsoft directory service for managing and securing network resources, user identities, and access permissions.',
    category: IntegrationCategory.auth,
    available: false,
  },
};

export interface CategoryContent {
  key: string;
  name: string;
  tag?: string;
}

export const Categories: Record<IntegrationCategory, Omit<CategoryContent, 'key'>> = {
  [IntegrationCategory.api]: {
    name: 'API',
    tag: 'API',
  },
  [IntegrationCategory.auth]: {
    name: 'Auth',
    tag: 'Auth',
  },
  [IntegrationCategory.crm]: {
    name: 'CRM',
    tag: 'CRM',
  },
  [IntegrationCategory.database]: {
    name: 'Databases',
    tag: 'Database',
  },
  [IntegrationCategory.observability]: {
    name: 'Observability',
    tag: 'Observability',
  },
  [IntegrationCategory.ai]: {
    name: 'AI',
    tag: 'AI',
  },
  [IntegrationCategory.queue]: {
    name: 'Queues',
    tag: 'Queue',
  },
  [IntegrationCategory.storage]: {
    name: 'Storage',
    tag: 'Storage',
  },
  [IntegrationCategory.saas]: {
    name: 'SaaS',
    tag: 'SaaS',
  },
};

export const AvailableCategories: Array<CategoryContent> = [
  { key: 'all', name: 'All integrations' },
  ...Object.entries(Categories).map(([key, category]) => ({
    key,
    ...category,
  })),
  { key: 'coming-soon', name: 'Coming Soon' },
];

export interface CategoryAndIntegrations {
  category: CategoryContent;
  integrations: Array<IntegrationContent>;
}

export interface IntegrationContent {
  name: string;
  description: string;
  type: IntegrationType;
  category: IntegrationCategory;
  available: boolean;
  docs?: string;
  tabs?: Array<IntegrationTab>;
  hideTestConnection?: boolean;
  deprecated?: boolean;
}

export function getIntegration(type: IntegrationType): IntegrationContent {
  const { name, description, docs, category, available } = Integrations[type];
  return { name, description, type, docs, category, available };
}

export const AllIntegrations = Object.entries(Integrations)
  .filter(
    ([type, integration]) =>
      ![
        IntegrationType.built_in_db,
        IntegrationType.built_in_queue,
        IntegrationType.built_in_s3,
        IntegrationType.built_in_gcs,
        IntegrationType.ai_agents,
      ].includes(type as IntegrationType) && !integration.deprecated,
  )
  .map(([type]) => getIntegration(type as IntegrationType));

export const ComingSoonIntegrations = Object.entries(Integrations)
  .filter(
    ([type, integration]) => type !== IntegrationType.built_in_db && !integration.available && !integration.deprecated,
  )
  .map(([type]) => getIntegration(type as IntegrationType));

export const PopularIntegrations = [
  getIntegration(IntegrationType.mongo),
  getIntegration(IntegrationType.mysql),
  getIntegration(IntegrationType.cockroach),
  getIntegration(IntegrationType.mssql),
  getIntegration(IntegrationType.api),
  getIntegration(IntegrationType.confluent),
];
