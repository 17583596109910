import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AiAgentId,
  AiChatModelName,
  AiContextId,
  AiProfileId,
  AppId,
  FunctionName,
  IntegrationType,
} from '@squidcloud/client';
import { BehaviorSubject, firstValueFrom, map, Observable, switchMap, take } from 'rxjs';
import { IntegrationService } from '../../integration.service';

import { FormControl } from '@angular/forms';
import { GlobalUiService } from '../../../global/services/global-ui.service';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { AiChatbotProfilesService } from './ai-chatbot-profiles.service';
import { AiTestChatFlyOutService } from './ai-test-chat-fly-out/ai-test-chat-fly-out.service';
import { isNonNullable, truthy } from 'assertic';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EmbedWidgetDialogComponent } from './embed-widget-dialog/embed-widget-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { getRequiredPageParameter, INTEGRATION_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { getMessageFromError } from '@squidcloud/internal-common/utils/error-utils';
import {
  AiChatbotIntegrationConfig,
  AiChatbotProfile,
  AiChatbotProfiles,
} from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import { CpIntegration } from '@squidcloud/console-common/types/application.types';
import { FormElement } from '@squidcloud/console-web/app/utils/form';
import {
  StorylaneDemo,
  StorylaneDialogService,
} from '@squidcloud/console-web/app/global/components/storylane-dialog/storylane-dialog.service';
import { getEntries, getSortedKeys } from '@squidcloud/console-web/app/utils/angular-utils';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { AiChatbotSelectFunctionDialogComponent } from '@squidcloud/console-web/app/integrations/schema/ai-chatbot-profiles/ai-chatbot-select-function-dialog/ai-chatbot-select-function-dialog.component';
import { AiFunctionMetadata } from '@squidcloud/internal-common/types/bundle-data.types';
import { getFunctionNameFromServiceFunctionName } from '@squidcloud/internal-common/utils/bundle-utils';
import { AiChatbotSelectConnectedAgentDialogComponent } from '@squidcloud/console-web/app/integrations/schema/ai-chatbot-profiles/ai-chatbot-select-connected-agent-dialog/ai-chatbot-select-connected-agent-dialog.component';

const TEST_CHATS: Record<string, string> = {
  onboarding: `Hello! I can help you understand car insurance. You can ask me questions like "What does my policy cover?" or "What impacts my insurance costs?" and more.`,
};

@Component({
  selector: 'ai-chatbot-profiles',
  templateUrl: '/ai-chatbot-profiles.component.html',
  styleUrls: ['./ai-chatbot-profiles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiChatbotProfilesComponent implements OnDestroy {
  readonly integration$: Observable<AiChatbotIntegrationConfig>;
  readonly chatbotProfiles$: Observable<AiChatbotProfiles | undefined>;
  readonly integrationId: string;
  readonly tipParam: string | undefined;

  private lastDemo: StorylaneDemo | undefined = undefined;
  private storylaneProfile: string | undefined;

  strictContextControl = new FormControl(false);

  ModelNameMap: Record<string, string> = {
    'gpt-4o': 'GPT-4o',
    'gpt-4o-mini': 'GPT-4o Mini',
    'o1-preview': 'GPT-o1 Preview',
    'o1-mini': 'GPT-o1 Mini',
    'gemini-1.5-pro': 'Gemini Pro 1.5',
    'gemini-1.5-flash': 'Gemini Flash 1.5',
    'claude-3-5-sonnet-latest': 'Claude 3.5 Sonnet',
    'claude-3-5-haiku-latest': 'Claude 3.5 Haiku',
  };

  @Output() headerTemplateChange = new EventEmitter<TemplateRef<unknown>>();

  private readonly selectedProfileRefSubject = new BehaviorSubject<AiProfileRef | undefined>(undefined);
  readonly selectedProfileId$ = this.selectedProfileRefSubject.pipe(map(ref => ref?.profileId));
  applicationObs = this.applicationService.observeCurrentApplication();

  constructor(
    { snapshot }: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly integrationService: IntegrationService,
    private readonly applicationService: ApplicationService,
    private readonly aiChatbotProfilesService: AiChatbotProfilesService,
    private readonly cdr: ChangeDetectorRef,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
    private readonly aiTestChatFlyOutService: AiTestChatFlyOutService,
    private readonly storylane: StorylaneDialogService,
  ) {
    this.tipParam = snapshot.queryParams['tip'];

    this.integrationId = getRequiredPageParameter(INTEGRATION_ID_PARAMETER, snapshot);
    this.integration$ = this.integrationService.observeIntegration(this.integrationId);
    this.chatbotProfiles$ = this.aiChatbotProfilesService.observeProfiles();
    this.applicationService.currentApplication$
      .pipe(
        switchMap(() => this.integration$.pipe(take(1))),
        takeUntilDestroyed(),
      )
      .subscribe(integration => {
        void aiChatbotProfilesService.initializeAiSchema(integration);
      });
    this.chatbotProfiles$.pipe(takeUntilDestroyed()).subscribe(schema => {
      this.setSelectedProfile(schema);
    });

    this.strictContextControl.valueChanges.subscribe((checked: boolean | null) => {
      void this.handleStrictContextCheck(checked);
    });
    this.aiTestChatFlyOutService
      .observeChatHistory()
      .pipe(takeUntilDestroyed())
      .subscribe(history => {
        if (!history.length) return;

        const lastMessage = history[history.length - 1];
        if (!this.isOnboarding || lastMessage.type === 'user') {
          this.storylane.clear();
          return;
        }

        // Play the demo if the profile has changed, and we haven't yet shown the final demo.
        if (this.selectedProfile !== this.storylaneProfile && this.lastDemo !== StorylaneDemo.AI_CONGRATS) {
          const demo = this.lastDemo ? StorylaneDemo.AI_CONGRATS : StorylaneDemo.AI_LEARN;
          this.storylane.play(demo, 5000).then(success => {
            if (!success) return;
            this.storylaneProfile = this.selectedProfile;
            this.lastDemo = demo;
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.aiTestChatFlyOutService.closeTestChat();
  }

  get hasProfiles(): boolean {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    return !!Object.keys(schema.profiles).length;
  }

  showAddContextDialog(): void {
    this.globalUiService
      .showDialogWithForm<AiFormDetailsType>(
        {
          title: 'Add Context',
          textLines: ['Provide specific additional knowledge the agent should use when answering users.'],
          submitButtonText: 'Add Context',
          formElements: [
            {
              type: 'input',
              required: true,
              nameInForm: 'title',
              label: 'Context title',
            },
            {
              type: 'select',
              required: true,
              nameInForm: 'type',
              label: 'Context type',
              defaultValue: 'text',
              options: [
                { name: 'Raw text', value: 'text' },
                { name: 'URL', value: 'url' },
                { name: 'File upload', value: 'file' },
              ],
            },
            {
              onChange: (formElement: FormElement, data: AiFormDetailsType): FormElement => {
                formElement.hidden = data.type !== 'text';
                return formElement;
              },
              type: 'textarea',
              required: true,
              nameInForm: 'text',
              label: 'Enter context',
              placeholder: 'Provide contextual knowledge here',
              attributes: {
                autosize: true,
                minRows: 12,
                maxRows: 16,
              },
            },
            {
              onChange: (formElement: FormElement, data: AiFormDetailsType): FormElement => {
                formElement.hidden = data.type !== 'url';
                return formElement;
              },
              type: 'input',
              required: true,
              nameInForm: 'url',
              label: 'Enter URL',
              hidden: true,
            },
            {
              onChange: (formElement: FormElement, data: AiFormDetailsType): FormElement => {
                formElement.hidden = data.type !== 'file';
                return formElement;
              },
              type: 'file',
              required: true,
              nameInForm: 'file',
              label: 'File upload',
              hidden: true,
              fileTypes: 'pdf, docx, html, and any text file',
            },
          ],
          onSubmit: async (data): Promise<string | void> => {
            if (!data) return;
            const title = data.title;
            const type = data.type;
            let value: undefined | string;
            switch (type) {
              case 'url':
                value = data.url;
                break;
              case 'file':
                value = data.file?.name;
                break;
              default:
                value = data.text;
                break;
            }
            try {
              await this.aiChatbotProfilesService.addContext(
                this.selectedProfileOrFail,
                title,
                {
                  type,
                  data: truthy(value, 'NO_CONTEXT_VALUE'),
                },
                (data as { file?: File }).file,
              );
              this.snackBar.success('Context added');
            } catch (error: unknown) {
              const message = error instanceof Error ? error.message : 'Unable to add context';
              this.snackBar.warning(message);
            }
          },
        },
        true,
      )
      .then();
  }

  showAddInstructionDialog(): void {
    this.globalUiService
      .showDialogWithForm<{ instruction: string }>(
        {
          title: 'Add instruction',
          textLines: ['Provide instructions for the LLM to follow when interacting with users.'],
          submitButtonText: 'Add instructions',
          formElements: [
            {
              type: 'textarea',
              required: true,
              nameInForm: 'instruction',
              placeholder: 'Enter instructions here',
              label: 'Instructions',
              attributes: {
                autosize: true,
                minRows: 10,
                maxRows: 15,
              },
            },
          ],
          onSubmit: async (data): Promise<string | void> => {
            if (!data) return;
            const instruction = data.instruction;
            try {
              await this.aiChatbotProfilesService.addInstruction(this.selectedProfileOrFail, instruction);
              this.snackBar.success('Instruction added');
            } catch (error: unknown) {
              const message = error instanceof Error ? error.message : 'Unable to add instructions';
              this.snackBar.warning(message);
            }
          },
        },
        true,
      )
      .then();
  }

  showDeleteContextDialog(contextId: AiContextId): void {
    this.globalUiService.showConfirmationDialog(
      'Arrrrr you sure?',
      `Deleting this context cannot be undone or recovered, and it will be removed from this profile.`,
      'Delete',
      async () => {
        try {
          await this.aiChatbotProfilesService.deleteContext(this.selectedProfileOrFail, contextId);
          this.snackBar.success('Context deleted');
        } catch {
          this.snackBar.warning('Unable to delete context');
        }
      },
    );
  }

  showDeleteInstructionDialog(id: string): void {
    this.globalUiService.showConfirmationDialog(
      'Arrrrr you sure?',
      `Deleting this instruction cannot be undone or recovered, and it will be removed from this profile.`,
      'Delete',
      async () => {
        try {
          await this.aiChatbotProfilesService.deleteInstruction(this.selectedProfileOrFail, id);
          this.snackBar.success('Instruction deleted');
        } catch {
          this.snackBar.warning('Unable to delete instruction');
        }
      },
    );
  }

  showEditInstructionDialog(profileId: string, instructionId: string, instructions: string): void {
    this.globalUiService
      .showDialogWithForm<{ instructions: string }>({
        title: 'Edit instructions',
        submitButtonText: 'Save',
        textLines: ['Edit the instructions for this chatbot'],
        autoFocus: true,
        formElements: [
          {
            type: 'textarea',
            required: true,
            nameInForm: 'instructions',
            defaultValue: instructions,
            label: 'Instructions',
            attributes: {
              autosize: true,
              minRows: 10,
              maxRows: 15,
            },
          },
        ],
        onSubmit: async data => {
          try {
            await this.aiChatbotProfilesService.updateInstruction(profileId, instructionId, data.instructions);
            this.snackBar.success('Instructions updated');
          } catch (error) {
            console.error('Unable to update instructions', error);
            this.snackBar.warning(`Unable to update instructions: ${getMessageFromError(error)}`);
          }
        },
      })
      .then();
  }

  showDeleteProfileDialog(id: string): void {
    void this.globalUiService.showDeleteDialog(
      `Deleting the ${id} profile cannot be undone or recovered, and it will be removed from this integration.`,
      async () => {
        try {
          await this.aiChatbotProfilesService.deleteProfile(this.selectedProfileOrFail);
          this.snackBar.success('Profile deleted');
          this.selectFirstProfile();
        } catch {
          this.snackBar.warning('Unable to delete profile');
        }
      },
      `Arrrrr you sure?`,
    );
  }

  showProfileDialog(id?: string): void {
    const isEdit = !!id;

    const modelOptions = Object.entries(this.ModelNameMap).map(([value, name]) => ({ name, value }));

    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    const profile = isEdit ? schema.profiles[id] : undefined;

    this.globalUiService
      .showDialogWithForm<{ id: string; modelName: AiChatModelName; public: boolean; auditLog: boolean }>({
        title: `${isEdit ? 'Edit' : 'Add'} agent?`,
        minRole: 'ADMIN',
        textLines: ['Agents allow you to inject context and instructions into your AI model prompts'],
        submitButtonText: isEdit ? 'Update' : 'Add',
        formElements: [
          {
            type: 'input',
            required: true,
            nameInForm: 'id',
            label: 'Agent ID',
            defaultValue: id,
            readonly: isEdit,
          },
          {
            type: 'select',
            required: true,
            nameInForm: 'modelName',
            label: 'Model name',
            options: modelOptions,
            defaultValue: profile?.modelName,
          },
          {
            type: 'boolean',
            required: true,
            nameInForm: 'public',
            label: 'Set agent to public',
            description:
              'Public agents are accessible to all users. Toggle this setting and view <a href="https://docs.getsquid.ai/docs/security-rules/secure-ai-chatbot" target="_blank">documentation</a> to restrict access.',
            defaultValue: isNonNullable(profile?.isPublic) ? profile?.isPublic : false,
          },
          {
            type: 'boolean',
            required: true,
            nameInForm: 'auditLog',
            label: 'Report audit logs',
            description:
              'Enable this setting to maintain a detailed log of the agent’s actions and interactions for auditing purposes.',
            defaultValue: isNonNullable(profile?.auditLog) ? profile?.auditLog : false,
          },
        ],
        onSubmit: async (data): Promise<string | void> => {
          if (!data) return;
          const profileId = data.id;
          const modelName = data.modelName;
          const isPublic = data.public;
          const auditLog = data.auditLog;

          if (isEdit) {
            try {
              await this.aiChatbotProfilesService.updateProfile(profileId, {
                modelName,
                isPublic,
                auditLog,
              });
              this.snackBar.success('Profile updated');
            } catch (error: unknown) {
              const message = error instanceof Error ? error.message : 'Unable to update profile';
              this.snackBar.warning(message);
            }
          } else {
            try {
              await this.aiChatbotProfilesService.addAgent(profileId, {
                modelName,
                isPublic,
                auditLog,
                strictContext: false,
              });
              this.selectProfile(profileId);
              this.snackBar.success('Profile created');
            } catch (error: unknown) {
              const message = error instanceof Error ? error.message : 'Unable to create profile';
              this.snackBar.warning(message);
            }
          }
        },
      })
      .then();
  }

  setSelectedProfile(schema: AiChatbotProfiles | undefined): void {
    const selectedProfileRef = this.selectedProfileRefSubject.value;
    if (!selectedProfileRef) {
      if (schema?.profiles) {
        this.selectFirstProfile();
      } else {
        this.aiTestChatFlyOutService.closeTestChat();
      }
    } else {
      const isAppChanged = selectedProfileRef.appId !== this.applicationService.getCurrentApplicationOrFail().appId;
      const isSelectedProfileExists = !!schema?.profiles[selectedProfileRef.profileId];
      if (isAppChanged || !isSelectedProfileExists) {
        if (schema?.profiles) {
          this.selectFirstProfile();
        } else {
          this.selectProfile(undefined);
        }
      } else {
        // Selected profile exists and is valid - the UI will reuse it.
      }
    }
  }

  selectProfile(profileId: string | undefined, introText?: string): void {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();

    const appId = this.applicationService.getCurrentApplication()?.appId;

    if (appId && profileId) {
      this.selectedProfileRefSubject.next({ appId, profileId });
      this.strictContextControl.setValue(schema.profiles[profileId].strictContext || false, { emitEvent: false });
      this.aiTestChatFlyOutService.setIntegrationIdAndProfileId(appId, this.integrationId, profileId, false, introText);
    } else {
      this.selectedProfileRefSubject.next(undefined);
    }

    this.cdr.markForCheck();
  }

  get selectedProfile(): string | undefined {
    return this.selectedProfileRefSubject.value?.profileId;
  }

  get selectedProfileOrFail(): string {
    return truthy(this.selectedProfile);
  }

  private selectFirstProfile(): void {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    const profiles = Object.keys(schema.profiles || []).sort();

    const introText = this.tipParam ? TEST_CHATS[this.tipParam] : undefined;
    this.selectProfile(profiles[0], introText);

    if (introText) {
      this.toggleTestChat();
      if (this.isOnboarding) {
        void this.storylane.play(StorylaneDemo.AI_SPLASH, 1000);
      }
    }
  }

  checkHasInstructions(profileId: string): boolean {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    return !!Object.keys(schema.profiles[profileId]?.instructions || {}).length;
  }

  checkHasContext(profileId: string): boolean {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    return !!Object.keys(schema.profiles[profileId]?.contexts || {}).length;
  }

  checkHasConnectedAiAgents(profileId: string): boolean {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    return !!schema.profiles[profileId]?.connectedProfiles?.length;
  }

  checkHasAiFunctions(profileId: string): boolean {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    return !!schema.profiles[profileId]?.functions?.length;
  }

  async handleStrictContextCheck(checked: boolean | null): Promise<void> {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    const profile = schema.profiles[this.selectedProfileOrFail];
    try {
      await this.aiChatbotProfilesService.updateProfile(this.selectedProfileOrFail, {
        ...profile,
        strictContext: !!checked,
      });
      this.snackBar.success('Profile context settings updated');
    } catch {
      this.strictContextControl.setValue(!checked, { emitEvent: false });
      this.snackBar.warning('Unable to update profile');
    }
  }

  toggleTestChat(): void {
    this.aiTestChatFlyOutService.toggleTestChat();
  }

  showEmbedWidgetDialog(integration: CpIntegration): void {
    EmbedWidgetDialogComponent.show(this.dialog, { integration, profileId: this.selectedProfile });
  }

  isAgent(integration: CpIntegration): boolean {
    return integration.type === IntegrationType.ai_agents;
  }

  get isOnboarding(): boolean {
    return this.tipParam === 'onboarding';
  }

  protected readonly getSortedKeys = getSortedKeys;
  protected readonly getEntries = getEntries;

  private getProfileById(profileId: string): AiChatbotProfile {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    return truthy(schema.profiles[profileId], `Profile not found: ${profileId}`);
  }

  async showAddConnectedAiAgentDialog(profileId: AiProfileId): Promise<void> {
    const allAiAgentIds = this.getAllAiAgentIds();
    if (allAiAgentIds.length <= 1) {
      void this.globalUiService.showMessageDialog('Unable to Add AI Agent', 'No other AI Agents available');
      return;
    }
    const agentIdsWithNoConflict = this.getAiAgentIdsThatDoNotUseCurrentAgent(profileId);
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    const connectedProfiles = schema.profiles[profileId]?.connectedProfiles || [];
    const availableAgentIds = agentIdsWithNoConflict.filter(id => !connectedProfiles.some(p => p.agentId === id));

    if (availableAgentIds.length === 0) {
      void this.globalUiService.showMessageDialog(
        'Unable to Add AI Agent',
        'No available AI Agents found. They are either already added or use the current Agent.',
      );
      return;
    }
    const result = await firstValueFrom(
      AiChatbotSelectConnectedAgentDialogComponent.show(this.dialog, { agentIds: availableAgentIds }).afterClosed(),
    );
    if (!result) return;
    if (!this.getAiAgentIdsThatDoNotUseCurrentAgent(profileId).includes(result.agentId)) {
      return;
    }
    const profileAfterAdd = this.getProfileById(profileId);
    const newProfileAgents = (profileAfterAdd.connectedProfiles || [])?.filter(p => p.agentId !== result.agentId);
    newProfileAgents.push(result);
    profileAfterAdd.connectedProfiles = newProfileAgents;
    void this.aiChatbotProfilesService.updateProfile(profileId, profileAfterAdd);
  }

  async showAddFunctionDialog(profileId: AiProfileId): Promise<void> {
    const allAiFunctions = this.getAllAiFunctions();
    if (allAiFunctions.length === 0) {
      void this.globalUiService.showMessageDialog(
        'Unable to Add AI Function',
        'No AI functions are available in your backend.',
      );
      return;
    }
    const profileBeforeAdd = this.getProfileById(profileId);
    const usedFunctions = profileBeforeAdd.functions || [];
    const notUsedAiFunctions = allAiFunctions.filter(
      f => !usedFunctions.includes(getFunctionNameFromServiceFunctionName(f.serviceFunction)),
    );
    if (notUsedAiFunctions.length === 0) {
      void this.globalUiService.showMessageDialog(
        'Unable to Add AI Function',
        'The profile already includes all available functions from the backend.',
      );
      return;
    }
    const selectedServiceFunctionName = await firstValueFrom(
      AiChatbotSelectFunctionDialogComponent.show(this.dialog, { functions: notUsedAiFunctions }).afterClosed(),
    );
    if (!selectedServiceFunctionName) return;
    const functionName = getFunctionNameFromServiceFunctionName(selectedServiceFunctionName);
    const profileAfterAdd = this.getProfileById(profileId);
    const newProfileFunctions = (profileAfterAdd.functions || [])?.filter(f => f !== functionName);
    newProfileFunctions.push(functionName);
    profileAfterAdd.functions = newProfileFunctions;
    void this.aiChatbotProfilesService.updateProfile(profileId, profileAfterAdd);
  }

  private getAllAiFunctions(): Array<AiFunctionMetadata> {
    return Object.values(this.applicationService.getCurrentApplicationOrFail().bundleData?.aiFunctions || {});
  }

  private getAllAiAgentIds(): Array<AiProfileId> {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    return Object.keys(schema.profiles);
  }

  private getAiAgentIdsThatDoNotUseCurrentAgent(currentAgentId: AiAgentId): Array<AiProfileId> {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    const allOtherAgentIds = Object.keys(schema.profiles).filter(id => id !== currentAgentId);
    const result: Array<AiProfileId> = [];
    const checkedAgentResults = new Map<AiAgentId, boolean>();
    for (const agentId of allOtherAgentIds) {
      const isDependencyLoop = checkIfAgentIdIsInConnectedAgentHierarchy(
        currentAgentId,
        agentId,
        checkedAgentResults,
        schema.profiles,
      );
      if (!isDependencyLoop) {
        result.push(agentId);
      }
    }
    return result;
  }

  showDeleteAiFunctionDialog(profileId: AiProfileId, aiFunctionToRemove: FunctionName): void {
    this.globalUiService.showConfirmationDialog(
      'Remove AI Function?',
      `Are you sure you want to remove the AI function <b>${aiFunctionToRemove}</b> from the AI agent?`,
      'Remove',
      () => {
        const profile = this.getProfileById(profileId);
        profile.functions = (profile.functions || []).filter(f => f !== aiFunctionToRemove);
        void this.aiChatbotProfilesService.updateProfile(profileId, profile);
      },
    );
  }

  showDeleteConnectedAiAgentDialog(profileId: AiProfileId, connectedProfileId: AiAgentId): void {
    this.globalUiService.showConfirmationDialog(
      'Remove connected AI Agent?',
      `Are you sure you want to remove the connected AI Agent <b>${connectedProfileId}</b> from the AI agent?`,
      'Remove',
      () => {
        const profile = this.getProfileById(profileId);
        profile.connectedProfiles = (profile.connectedProfiles || []).filter(f => f.agentId !== connectedProfileId);
        void this.aiChatbotProfilesService.updateProfile(profileId, profile);
      },
    );
  }

  getAiFunctionDescription(functionName: FunctionName): string {
    const allAiFunctions = this.getAllAiFunctions();
    const aiFunction = allAiFunctions.find(
      f => getFunctionNameFromServiceFunctionName(f.serviceFunction) === functionName,
    );
    return aiFunction?.description || '';
  }
}

type AiFormDetailsType = { title: string; name: string } & (
  | { type: 'file'; file?: File }
  | { type: 'text'; text?: string }
  | { type: 'url'; url?: string }
);

interface AiProfileRef {
  appId: AppId;
  profileId: AiProfileId;
}

function checkIfAgentIdIsInConnectedAgentHierarchy(
  agentId: AiAgentId,
  agentIdToCheck: AiAgentId,
  checkedAgentResults: Map<AiAgentId, boolean>,
  allProfiles: Record<AiAgentId, AiChatbotProfile>,
): boolean {
  const checkedResult = checkedAgentResults.get(agentIdToCheck);
  if (checkedResult !== undefined) {
    return checkedResult;
  }
  const agentToCheck = allProfiles[agentIdToCheck];
  if (!agentToCheck?.connectedProfiles) {
    checkedAgentResults.set(agentIdToCheck, false);
    return false;
  }
  for (const agentInfo of agentToCheck.connectedProfiles) {
    if (
      agentInfo.agentId === agentId ||
      checkIfAgentIdIsInConnectedAgentHierarchy(agentId, agentInfo.agentId, checkedAgentResults, allProfiles)
    ) {
      checkedAgentResults.set(agentIdToCheck, true);
      return true;
    }
  }
  checkedAgentResults.set(agentIdToCheck, false);
  return false;
}
