import { BackendMetricsChartData } from '@squidcloud/console-common/types/metrics.types';

export interface BundleDataTableRow {
  columns: Array<BundleDataTableColumn>;
}

export interface BundleDataTableColumn {
  value: string;
  /** Values used for column sorting. If not provided the text of 'value' is used. */
  sortValue?: number;
  tooltip?: string;
  link?: string;
  target?: string;
  /** Class applied to the <a> tag that surrounds the value inside the cell. */
  class?: string;
}

export interface BundleDataTableColumnHeader {
  name: string;
  /**
   * Table cell content alignment. Default: no alignment ('left').
   * Affects header cell and all cells in the column.
   */
  align?: 'left' | 'right';
  /** Tooltip text shown when user hover the header. */
  tooltip?: string;
  /** When present every cell in the column has a 'Copy' button. */
  getCopyValue?: (column: BundleDataTableColumn, row: BundleDataTableRow) => string;
  copyButtonText?: string;
}

export interface BundleDataTableData {
  headerRow: Array<string | BundleDataTableColumnHeader>;

  rows: Array<BundleDataTableRow>;

  /** When provides charts line will be shown on top of the tables. */
  metrics?: BackendMetricsChartData;

  /** When set to true the period selector will be shown. See. BackendMetricPeriodType. */
  showPeriodSelector?: boolean;
}

export const medianColumnHeader: BundleDataTableColumnHeader = {
  name: 'Median',
  align: 'right',
  tooltip: 'Median response time (50th percentile): Half of the requests are completed in this time or less.',
};

export const p95ColumnHeader: BundleDataTableColumnHeader = {
  name: 'P95',
  align: 'right',
  tooltip:
    '95th percentile response time: 95% of the requests are completed in this time or less, with the slowest 5% taking longer.',
};
