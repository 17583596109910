import { IntegrationId, IntegrationInfo, IntegrationType } from './public-types';
import { RpcManager } from './rpc.manager';

export class IntegrationClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  async list<ConfigurationType = Record<string, any>>(
    type?: IntegrationType,
  ): Promise<Array<IntegrationInfo<ConfigurationType>>> {
    const integrations = await this.rpcManager.post<IntegrationInfo[]>('integration/getIntegrations', {});
    const result = type ? integrations.filter(integration => integration.type === type) : integrations;
    return result as Array<IntegrationInfo<ConfigurationType>>;
  }

  async get<ConfigurationType = Record<string, any>>(
    id: IntegrationId,
  ): Promise<IntegrationInfo<ConfigurationType> | undefined> {
    const integrations = await this.rpcManager.post<IntegrationInfo[]>('integration/getIntegrations', {});
    return integrations.find(integration => integration.id === id) as IntegrationInfo<ConfigurationType>;
  }
}
