import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AiAgentId } from '@squidcloud/client';
import { AiConnectedAgentMetadata } from '@squidcloud/internal-common/types/ai-chatbot.types';
import { FormConfig } from '@squidcloud/console-web/app/global/components/magic-form/magic-form.component';

@Component({
  selector: 'app-ai-chatbot-select-connected-agent-dialog',
  templateUrl: './ai-chatbot-select-connected-agent-dialog.component.html',
  styleUrl: './ai-chatbot-select-connected-agent-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiChatbotSelectConnectedAgentDialogComponent {
  readonly formConfig: FormConfig = {
    formElements: [],
    cancelButtonText: 'Cancel',
    submitButtonText: 'Add',
    disableSubmitButtonWhenPristine: true,
    layoutType: 'dialog',
    onSubmit: res => this.onSubmit(res),
    onCancel: () => this.dialogRef.close(undefined),
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) protected readonly data: AiChatbotSelectConnectedAgentDialogData,
    protected readonly dialogRef: MatDialogRef<
      AiChatbotSelectConnectedAgentDialogComponent,
      AiChatbotSelectConnectedAgentDialogResult | undefined
    >,
  ) {
    this.formConfig.formElements.push({
      type: 'select',
      label: 'Connected Agent',
      nameInForm: 'agentId',
      required: true,
      options: data.agentIds.map(agentId => ({
        name: agentId,
        value: agentId,
      })),
    });
    this.formConfig.formElements.push({
      type: 'textarea',
      label: 'Description',
      nameInForm: 'description',
      required: true,
      attributes: { autosize: true, minRows: 3, maxRows: 50 },
    });
  }

  static show(
    dialog: MatDialog,
    data: AiChatbotSelectConnectedAgentDialogData,
  ): MatDialogRef<AiChatbotSelectConnectedAgentDialogComponent, AiChatbotSelectConnectedAgentDialogResult> {
    return dialog.open<
      AiChatbotSelectConnectedAgentDialogComponent,
      AiChatbotSelectConnectedAgentDialogData,
      AiChatbotSelectConnectedAgentDialogResult
    >(AiChatbotSelectConnectedAgentDialogComponent, {
      minWidth: 500,
      maxWidth: 800,
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data,
    });
  }

  onSubmit(res: Record<string, unknown>): void {
    const agentId = res['agentId'] as AiAgentId | undefined;
    const description = res['description'] as string | undefined;
    if (agentId && description) {
      this.dialogRef.close({ agentId, description });
    } else {
      this.dialogRef.close(undefined);
    }
  }
}

export interface AiChatbotSelectConnectedAgentDialogData {
  agentIds: Array<AiAgentId>;
}

export type AiChatbotSelectConnectedAgentDialogResult = AiConnectedAgentMetadata;
