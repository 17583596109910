import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, inject, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OpenApiTutorialComponent } from '@squidcloud/console-web/app/backend/openapi/openapi-tutorial.component';
import { OpenApiComponent } from '@squidcloud/console-web/app/backend/openapi/openapi.component';
import { PopoverModule } from '@squidcloud/console-web/app/global/popover/popover.module';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha-2';
import { MarkdownModule } from 'ngx-markdown';
import { ProfileSettingsComponent } from './account/profile-settings/profile-settings.component';
import { AppComponent } from './app.component';
import { ApplicationOverviewComponent } from './application/application-overview/application-overview.component';
import { ApplicationSettingsComponent } from './application/application-settings/application-settings.component';
import { CreateApplicationDialogComponent } from './application/create-application-dialog/create-application-dialog.component';
import { CreateApplicationFormComponent } from './application/create-application-form/create-application-form.component';
import { DocumentationWithComponentDialogComponent } from '@squidcloud/console-web/app/application/documentation-with-component-dialog/documentation-with-component-dialog.component';
import { LogEntryDialogComponent } from './application/logs/log-entry-dialog/log-entry-dialog.component';
import { LogLevelComponent } from './application/logs/log-level/log-level.component';
import { LogsComponent } from './application/logs/logs.component';
import { NoApplicationZeroStateComponent } from './application/no-application-zero-state/no-application-zero-state.component';
import { CreateSecretDialogComponent } from './application/secrets/create-secret-dialog/create-secret-dialog.component';
import { SecretsComponent } from './application/secrets/secrets.component';
import { UpdateSecretDialogComponent } from './application/secrets/update-secret-dialog/update-secret-dialog.component';
import { BackendComponent } from './backend/backend.component';
import { CreateEnvFileDocComponent } from './backend/create-env-file-doc/create-env-file-doc.component';
import { DeployBackendDocComponent } from './backend/deploy-backend-doc/deploy-backend-doc.component';
import { ExecutablesTutorialComponent } from './backend/executables/executables-tutorial.component';
import { ExecutablesComponent } from './backend/executables/executables.component';
import { InitializeBackendTutorialComponent } from './backend/initialize-backend-tutorial/initialize-backend-tutorial.component';
import { SchedulersTutorialComponent } from './backend/schedulers/schedulers-tutorial.component';
import { SchedulersComponent } from './backend/schedulers/schedulers.component';
import { SecurityRulesTutorialComponent } from './backend/security-rules/security-rules-tutorial.component';
import { SecurityRulesComponent } from './backend/security-rules/security-rules.component';
import { ShowEnvVarsDocComponent } from './backend/show-env-vars-doc/show-env-vars-doc.component';
import { TriggersTutorialComponent } from './backend/triggers/triggers-tutorial.component';
import { TriggersComponent } from './backend/triggers/triggers.component';
import { WebhooksTutorialComponent } from './backend/webhooks/webhooks-tutorial.component';
import { WebhooksComponent } from './backend/webhooks/webhooks.component';
import { FeedbackDialogComponent } from '@squidcloud/console-web/app/global/components/feedback-dialog/feedback-dialog.component';
import { BundleDataTableComponent } from './global/components/bundle-data-table/bundle-data-table.component';
import { ActionComponent } from './global/components/cards/action/action.component';
import { InfoComponent } from './global/components/cards/info/info.component';
import { CopyCodeComponent } from './global/components/copy-code/copy-code.component';
import { DialogWithFormComponent } from './global/components/dialog-with-form/dialog-with-form.component';
import { DocComponent } from './global/components/doc/doc.component';
import { EmailVerificationComponent } from './global/components/email-verification/email-verification.component';
import { HeaderComponent } from './global/components/header/header.component';
import { ComingSoonComponent } from './global/components/illustrations/coming-soon/coming-soon.component';
import { IllustrationComponent } from './global/components/illustrations/illustration/illustration.component';
import { NothingToSeeComponent } from './global/components/illustrations/nothing-to-see/nothing-to-see.component';
import { InputCardComponent } from './global/components/input-card/input-card.component';
import { LogoutComponent } from './global/components/logout/logout.component';
import { MiniBundleDataTableComponent } from './global/components/mini-bundle-data-table/mini-bundle-data-table.component';
import { PageZeroStateComponent } from './global/components/page-zero-state/page-zero-state.component';
import { PortalComponent } from './global/components/portal/portal.component';
import { ProtectedLayoutComponent } from './global/components/protected-layout/protected-layout.component';
import { QuotedActionDialogComponent } from './global/components/quoted-action-dialog/quoted-action-dialog.component';
import { RudderChatComponent } from './global/components/rudder-chat/rudder-chat.component';
import { WidgetContactComponent } from './global/components/rudder-chat/widget-contact/widget-contact.component';
import { SidebarLayoutComponent } from './global/components/sidebar-layout/sidebar-layout.component';
import { StatusPageComponent } from './global/components/status-page/status-page.component';
import { AutofocusDirective } from './global/directives/autofocus.directive';
import { LabelWithPrefixDirective } from './global/directives/label-with-prefix.directive';
import { MinRolePipe } from './global/pipes/min-role.pipe';
import { ObjectEntriesPipe } from './global/pipes/object-entries.pipe';
import { ObjectKeysPipe } from './global/pipes/object-keys.pipe';
import { ObjectValuesPipe } from './global/pipes/object-values.pipe';
import { ParseAppIdPipe } from './global/pipes/parse-app-id.pipe';
import { SortPipe } from './global/pipes/sort.pipe';
import { AvailableIntegrationsComponent } from './integrations/available-integrations/available-integrations.component';
import { InjectionTableComponent } from './integrations/components/injection-table/injection-table.component';
import { IntegrationHeaderComponent } from './integrations/components/integration-header/integration-header.component';
import { IntegrationIdComponent } from './integrations/components/integration-id/integration-id.component';
import { MiniBackendFunctionsComponent } from './integrations/components/mini-backend-functions/mini-backend-functions.component';
import { CurrentIntegrationsComponent } from './integrations/current-integrations/current-integrations.component';
import { InjectionTableFormComponent } from './integrations/integration-form/components/injection-table-form/injection-table-form.component';
import { IntegrationFormComponent } from './integrations/integration-form/integration-form.component';
import { IntegrationComponent } from './integrations/integration/integration.component';
import { IntegrationsComponent } from './integrations/integrations/integrations.component';
import { MetricsComponent } from './integrations/metrics/metrics.component';
import { AiChatbotProfilesComponent } from './integrations/schema/ai-chatbot-profiles/ai-chatbot-profiles.component';
import { AiTestChatFlyOutComponent } from './integrations/schema/ai-chatbot-profiles/ai-test-chat-fly-out/ai-test-chat-fly-out.component';
import { EmbedWidgetDialogComponent } from './integrations/schema/ai-chatbot-profiles/embed-widget-dialog/embed-widget-dialog.component';
import { ApiSchemaComponent } from './integrations/schema/api-schema/api-schema.component';
import { ReactGraphiqlLazyLoadedComponent } from './integrations/schema/components/react-graphiql/react-graphiql-lazy-loaded.component';
import { SchemaCardItemComponent } from './integrations/schema/components/schema-card-item/schema-card-item.component';
import { SchemaCardComponent } from './integrations/schema/components/schema-card/schema-card.component';
import { SchemaFieldMenuComponent } from './integrations/schema/components/schema-field-menu/schema-field-menu.component';
import { SchemaSelectRowComponent } from './integrations/schema/components/schema-select-row/schema-select-row.component';
import { SchemaTableHeaderComponent } from './integrations/schema/components/schema-table-header/schema-table-header.component';
import { DataSchemaFieldDialogComponent } from './integrations/schema/data-schema/data-schema-field-dialog/data-schema-field-dialog.component';
import { DataSchemaComponent } from './integrations/schema/data-schema/data-schema.component';
import { GraphqlSchemaComponent } from './integrations/schema/graphql-schema/graphql-schema.component';
import { SchemaComponent } from './integrations/schema/schema.component';
import { OnboardingApplicationComponent } from './onboarding/onboarding-application/onboarding-application.component';
import { OnboardingDoneComponent } from './onboarding/onboarding-done/onboarding-done.component';
import { OnboardingGettingStartedComponent } from './onboarding/onboarding-getting-started/onboarding-getting-started.component';
import { OnboardingWelcomeComponent } from './onboarding/onboarding-welcome/onboarding-welcome.component';
import { OnboardingWidgetGettingStartedComponent } from './onboarding/onboarding-widget-getting-started/onboarding-widget-getting-started.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { BillingPlansDialogComponent } from './organization/organization-billing/billing-plans-dialog/billing-plans-dialog.component';
import { OrganizationBillingComponent } from './organization/organization-billing/organization-billing.component';
import { SavePaymentDetailsDialogComponent } from './organization/organization-billing/save-payment-details-dialog/save-payment-details-dialog.component';
import { OrganizationDetailsComponent } from './organization/organization-details/organization-details.component';
import { OrganizationMembersComponent } from './organization/organization-members/organization-members.component';
import { QuotasComponent } from '@squidcloud/console-web/app/usage/quotas/quotas.component';
import { OrganizationComponent } from './organization/organization.component';
import { QuickStartFlyOutComponent } from './quick-start/quick-start-fly-out/quick-start-fly-out.component';
import { QuickStartTaskComponent } from './quick-start/quick-start-task/quick-start-task.component';
import { DbBrowserComponent } from './db-browser/db-browser.component';
import { DbBrowserRowComponent } from './db-browser/db-browser-row/db-browser-row.component';
import { DbBrowserFilterDialogComponent } from './db-browser/db-browser-filter-dialog/db-browser-filter-dialog.component';
import { DbBrowserFilterBubbleComponent } from './db-browser/db-browser-filter-bubble/db-browser-filter-bubble.component';
import { ContactUsDialogComponent } from './global/components/contact-us-dialog/contact-us-dialog.component';
import { IpAddressesDocComponent } from './application/ip-addresses-doc/ip-addresses-doc.component';
import { DocumentationDialogComponent } from './application/documentation-dialog/documentation-dialog.component';
import { ApplicationUsageComponent } from './application/application-usage/application-usage.component';
import { OnboardingEntryComponent } from './onboarding/onboarding-entry/onboarding-entry.component';
import { OnboardingCardsComponent } from './onboarding/onboarding-cards/onboarding-cards.component';
import { SeabedComponent } from './global/components/seabed/seabed.component';
import { OnboardingStepComponent } from './onboarding/onboarding-step/onboarding-step.component';
import { JsonEditorComponent } from '@squidcloud/console-web/app/db-browser/json-editor.component';
import { ChartLazyLoadedComponent } from '@squidcloud/console-web/app/metric/chart/chart-lazy-loaded.component';
import { TemplateTypeGuardDirective } from '@squidcloud/console-web/app/global/directives/template-type-guard.directive';
import { ConsoleCommonModule } from '@squidcloud/console-web/app/common/console-common.module';
import { StorylaneDialogComponent } from './global/components/storylane-dialog/storylane-dialog.component';
import { CarouselComponent } from './global/components/carousel/carousel.component';
import { HowToUseComponent } from './integrations/how-to-use/how-to-use.component';
import { OrganizationContactInfoComponent } from './organization/organization-contact-info/organization-contact-info.component';
import { MatSortModule } from '@angular/material/sort';
import { LinkedAccountsComponent } from './account/linked-accounts/linked-accounts.component';
import { UserLockPageComponent } from '@squidcloud/console-web/app/global/components/user-lock-page/user-lock-page.component';
import { SecretsScriptDialogComponent } from '@squidcloud/console-web/app/application/secrets/create-secrets-script-dialog/create-secrets-script-dialog.component';
import { provideMarkdown, provideSegments, setupWindowGlobals } from '@squidcloud/console-web/app/utils/app-init-utils';
import { APP_ROUTES } from '@squidcloud/console-web/app/app-routing';
import { provideRouter, RouterModule } from '@angular/router';
import { ThemeService } from '@squidcloud/console-web/app/global/services/theme.service';
import { AiFunctionsComponent } from './backend/ai-functions/ai-functions.component';
import { AiFunctionsTutorialComponent } from './backend/ai-functions/ai-functions-tutorial.component';
import { AiChatbotSelectFunctionDialogComponent } from './integrations/schema/ai-chatbot-profiles/ai-chatbot-select-function-dialog/ai-chatbot-select-function-dialog.component';
import { AuditLogsComponent } from './integrations/audit-logs/audit-logs.component';
import { AuditLogEntryDialogComponent } from './integrations/audit-logs/audit-log-entry-dialog/audit-log-entry-dialog.component';
import { AiChatbotSelectConnectedAgentDialogComponent } from '@squidcloud/console-web/app/integrations/schema/ai-chatbot-profiles/ai-chatbot-select-connected-agent-dialog/ai-chatbot-select-connected-agent-dialog.component';

@NgModule({
  declarations: [
    ActionComponent,
    AiChatbotProfilesComponent,
    AiFunctionsComponent,
    AiFunctionsTutorialComponent,
    AiTestChatFlyOutComponent,
    ApiSchemaComponent,
    AppComponent,
    ApplicationOverviewComponent,
    ApplicationSettingsComponent,
    ApplicationUsageComponent,
    AutofocusDirective,
    AvailableIntegrationsComponent,
    BackendComponent,
    BillingPlansDialogComponent,
    BundleDataTableComponent,
    CarouselComponent,
    ChartLazyLoadedComponent,
    ComingSoonComponent,
    ContactUsDialogComponent,
    CopyCodeComponent,
    CreateApplicationDialogComponent,
    CreateApplicationFormComponent,
    CreateEnvFileDocComponent,
    CreateSecretDialogComponent,
    CurrentIntegrationsComponent,
    DataSchemaComponent,
    DataSchemaFieldDialogComponent,
    DbBrowserComponent,
    DbBrowserFilterBubbleComponent,
    DbBrowserFilterDialogComponent,
    DbBrowserRowComponent,
    DeployBackendDocComponent,
    DialogWithFormComponent,
    DocComponent,
    DocumentationDialogComponent,
    DocumentationWithComponentDialogComponent,
    EmailVerificationComponent,
    EmbedWidgetDialogComponent,
    ExecutablesComponent,
    ExecutablesTutorialComponent,
    FeedbackDialogComponent,
    GraphqlSchemaComponent,
    HeaderComponent,
    HowToUseComponent,
    IllustrationComponent,
    InfoComponent,
    InitializeBackendTutorialComponent,
    InjectionTableComponent,
    InjectionTableFormComponent,
    InputCardComponent,
    IntegrationComponent,
    IntegrationFormComponent,
    IntegrationHeaderComponent,
    IntegrationIdComponent,
    IntegrationsComponent,
    IpAddressesDocComponent,
    JsonEditorComponent,
    LabelWithPrefixDirective,
    LinkedAccountsComponent,
    LogEntryDialogComponent,
    LogLevelComponent,
    LogoutComponent,
    LogsComponent,
    MetricsComponent,
    MinRolePipe,
    MiniBackendFunctionsComponent,
    MiniBundleDataTableComponent,
    NoApplicationZeroStateComponent,
    NothingToSeeComponent,
    ObjectEntriesPipe,
    ObjectKeysPipe,
    ObjectValuesPipe,
    OnboardingApplicationComponent,
    OnboardingCardsComponent,
    OnboardingComponent,
    OnboardingDoneComponent,
    OnboardingEntryComponent,
    OnboardingGettingStartedComponent,
    OnboardingStepComponent,
    OnboardingWelcomeComponent,
    OnboardingWidgetGettingStartedComponent,
    OpenApiComponent,
    OpenApiTutorialComponent,
    OrganizationBillingComponent,
    OrganizationComponent,
    OrganizationContactInfoComponent,
    OrganizationDetailsComponent,
    OrganizationMembersComponent,
    PageZeroStateComponent,
    ParseAppIdPipe,
    PortalComponent,
    ProfileSettingsComponent,
    ProtectedLayoutComponent,
    QuickStartFlyOutComponent,
    QuickStartTaskComponent,
    QuotasComponent,
    QuotedActionDialogComponent,
    ReactGraphiqlLazyLoadedComponent,
    RudderChatComponent,
    SavePaymentDetailsDialogComponent,
    SchedulersComponent,
    SchedulersTutorialComponent,
    SchemaCardComponent,
    SchemaCardItemComponent,
    SchemaComponent,
    SchemaFieldMenuComponent,
    SchemaSelectRowComponent,
    SchemaTableHeaderComponent,
    SeabedComponent,
    SecretsComponent,
    SecretsScriptDialogComponent,
    SecurityRulesComponent,
    SecurityRulesTutorialComponent,
    ShowEnvVarsDocComponent,
    SidebarLayoutComponent,
    SortPipe,
    StatusPageComponent,
    StorylaneDialogComponent,
    TriggersComponent,
    TriggersTutorialComponent,
    UpdateSecretDialogComponent,
    UserLockPageComponent,
    WebhooksComponent,
    WebhooksTutorialComponent,
    WidgetContactComponent,
    AiChatbotSelectFunctionDialogComponent,
    AuditLogsComponent,
    AuditLogEntryDialogComponent,
    AiChatbotSelectConnectedAgentDialogComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    ConsoleCommonModule,
    FormsModule,
    MarkdownModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    NgOptimizedImage,
    PopoverModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    RouterModule,
    TemplateTypeGuardDirective,
    TextFieldModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [provideRouter(APP_ROUTES), provideMarkdown(), provideSegments()],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    inject(ThemeService); // Initialize theme service as soon as possible, so the icons are available.
    console.debug('Starting Squid Console application');
    setupWindowGlobals();
  }
}
