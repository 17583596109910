import { FieldSort, isSimpleCondition, Operator, Query, SimpleCondition } from '../public-types/query.public-types';
import { DeepRecord, FieldOf, PartialBy, Paths } from '../public-types/typescript.public-types';
import { CollectionName, DocumentData, FieldName, FieldType } from '../public-types/document.public-types';
import { IntegrationId } from '../public-types/communication.public-types';
import { compareOperator } from '../types/query.types';
import { getInPath, isEqual } from '../utils/object';

/**
 * The compare table is used to determine whether one condition (conditionA)
 * is a subset of another condition (conditionB).
 *
 * The keys to the table are in the form of ${conditionA.operator}:${conditionB.operator},
 * and the return values are functions, that when passed (conditionA.value, conditionB.value)
 * will return whether conditionA is a subset of conditionB.
 *
 * The following example:
 *   '==:==': (a, b) => a === b,
 * Can be read as:
 *   ("x", "==", a) is a subset of ("x", "==", b) when a === b
 */
const CompareTable: Record<string, (a: any, b: any) => boolean> = {
  'in:in': (a, b) => a.every((c: any) => b.includes(c)),

  'in:not in': (a, b) => a.every((c: any) => !b.includes(c)),
  'not in:not in': (a, b) => b.every((c: any) => a.includes(c)),
  '>:not in': (a, b) => b.every((c: any) => a >= c),
  '>=:not in': (a, b) => b.every((c: any) => a > c),
  '<:not in': (a, b) => b.every((c: any) => a <= c),
  '<=:not in': (a, b) => b.every((c: any) => a < c),

  '>:>': (a, b) => a >= b,
  '>=:>': (a, b) => a > b,
  'in:>': (a, b) => a.every((c: any) => c > b),

  '>:>=': (a, b) => a >= b,
  '>=:>=': (a, b) => a >= b,
  'in:>=': (a, b) => a.every((c: any) => c >= b),

  '<:<': (a, b) => a <= b,
  '<=:<': (a, b) => a < b,
  'in:<': (a, b) => a.every((c: any) => c < b),

  '<:<=': (a, b) => a <= b,
  '<=:<=': (a, b) => a <= b,
  'in:<=': (a, b) => a.every((c: any) => c <= b),

  'like:like': (a: string, b: string) => isSubqueryString(a.toLowerCase(), b.toLowerCase()),
  'like_cs:like': (a: string, b: string) => isSubqueryString(a.toLowerCase(), b.toLowerCase()),
  'like:like_cs': (a: string, b: string) => isSubqueryString(a, b) && containsNoAlphabetical(b),
  'like_cs:like_cs': (a: string, b: string) => isSubqueryString(a, b),

  'like:not like': (a: string, b: string) => !hasOverlap(a.toLowerCase(), b.toLowerCase()),
  'like_cs:not like': (a: string, b: string) => !hasOverlap(a.toLowerCase(), b.toLowerCase()),
  'like:not like_cs': (a: string, b: string) => !hasOverlap(a.toLowerCase(), b.toLowerCase()),
  'like_cs:not like_cs': (a: string, b: string) => !hasOverlap(a, b),

  'not like:like': (a: string, b: string) => notLikeAndLike(a, b),
  'not like_cs:like': (a: string, b: string) => notLikeAndLike(a, b),
  'not like:like_cs': (a: string, b: string) => notLikeAndLike(a, b),
  'not like_cs:like_cs': (a: string, b: string) => notLikeAndLike(a, b),

  'not like:not like': (a: string, b: string) => isSubqueryString(b.toLowerCase(), a.toLowerCase()),
  'not like_cs:not like': (a: string, b: string) => isSubqueryString(b, a) && containsNoAlphabetical(a),
  'not like:not like_cs': (a: string, b: string) => isSubqueryString(b.toLowerCase(), a.toLowerCase()),
  'not like_cs:not like_cs': (a: string, b: string) => isSubqueryString(b, a),

  'in:like': (a: string[], b: string) => a.every(s => compareOperator(b, s, 'like')),
  'in:like_cs': (a: string[], b: string) => a.every(s => compareOperator(b, s, 'like_cs')),

  'in:not like': (a: string[], b: string) => a.every(s => compareOperator(b, s, 'not like')),
  'in:not like_cs': (a: string[], b: string) => a.every(s => compareOperator(b, s, 'not like_cs')),

  'like:in': (a: string, b: string[]) =>
    !a.includes('%') && !a.includes('_') && !!b.find(s => s.toLowerCase() === a.toLowerCase()),
  'like_cs:in': (a: string, b: string[]) => !a.includes('%') && !a.includes('_') && b.includes(a),

  'not like:in': (a: string, b: string[]) =>
    (a.length > 0 && isStringAllWildcard(a)) || (matchesAllNonEmptyStrings(a) && b.includes('')),
  'not like_cs:in': (a: string, b: string[]) =>
    (a.length > 0 && isStringAllWildcard(a)) || (matchesAllNonEmptyStrings(a) && b.includes('')),

  'not in:like': (a: string[], b: string) =>
    (b.length > 0 && isStringAllWildcard(b)) || (matchesAllNonEmptyStrings(b) && a.includes('')),
  'not in:like_cs': (a: string[], b: string) =>
    (b.length > 0 && isStringAllWildcard(b)) || (matchesAllNonEmptyStrings(b) && a.includes('')),

  'not in:not like': (a: string[], b: string) =>
    !b.includes('%') && !b.includes('_') && !!a.find(s => s.toLowerCase() === b.toLowerCase()),
  'not in:not like_cs': (a: string[], b: string) => !b.includes('%') && !b.includes('_') && a.includes(b),

  'like:not in': (a: string, b: string[]) => b.every(s => compareOperator(a, s, 'not like')),
  'like_cs:not in': (a: string, b: string[]) => b.every(s => compareOperator(a, s, 'not like_cs')),

  'not like:not in': (a: string, b: string[]) => b.every(s => compareOperator(a, s, 'like')),
  'not like_cs:not in': (a: string, b: string[]) => b.every(s => compareOperator(a, s, 'like_cs')),

  'array_includes_some:array_includes_some': (a, b) => a.every((c: any) => b.includes(c)),
  'array_includes_all:array_includes_all': (a, b) => a.every((c: any) => b.includes(c)),
  'array_not_includes:array_not_includes': (a, b) => b.every((c: any) => a.includes(c)),
  'array_includes_some:array_not_includes': (a, b) => a.every((c: any) => !b.includes(c)),
  'array_not_includes:array_includes_some': (a, b) => b.every((c: any) => !a.includes(c)),
  'array_includes_all:array_includes_some': (a, b) => a.every((c: any) => b.includes(c)),
  'array_includes_some:array_includes_all': (a, b) => a.every((c: any) => b.includes(c)),
  'array_not_includes:array_includes_all': (a, b) => b.every((c: any) => !a.includes(c)),
  'array_includes_all:array_not_includes': (a, b) => a.every((c: any) => !b.includes(c)),
};

/**
 * Checks whether a string expression is a subquery of the other. % are treated as wildcard (similar to .* in regex).
 * @param subquery The subquery parameter.
 * @param query The parent query parameter.
 * @param ind1 Used in the recursive call. Indicates which index in the subquery the recursive call is checking.
 * @param ind2 Used in the recursive call. Indicates which index in the query the recursive call is checking.
 */
function isSubqueryString(subquery: string, query: string, ind1: number = 0, ind2: number = 0): boolean {
  if (ind2 >= query.length) {
    return ind1 >= subquery.length;
  }
  if (ind1 >= subquery.length) {
    return isStringAllWildcard(query.substring(ind2));
  }
  const char1 = subquery[ind1];
  const char2 = query[ind2];
  if (char1 === '%' && char2 === '%') {
    return isSubqueryString(subquery, query, ind1 + 1, ind2 + 1) || isSubqueryString(subquery, query, ind1 + 1, ind2);
  }
  if (char1 === '%') {
    return false;
  }
  if (char2 === '%') {
    return isSubqueryString(subquery, query, ind1, ind2 + 1) || isSubqueryString(subquery, query, ind1 + 1, ind2);
  }

  return char1 === char2 || char2 === '_' ? isSubqueryString(subquery, query, ind1 + 1, ind2 + 1) : false;
}

/**
 * Checks whether there is any overlap between exp1 and exp2 (in other words, are there any strings that match both exp1
 * and exp2). % are treated as wildcard (similar to .* in regex). ind1 and ind2 are used in recursive calls to indicate
 * which characters are being checked.
 */
function hasOverlap(exp1: string, exp2: string, ind1: number = 0, ind2: number = 0): boolean {
  if (ind1 >= exp1.length && ind2 >= exp2.length) {
    return true;
  }
  if (ind1 >= exp1.length) {
    return isStringAllWildcard(exp2.substring(ind2));
  }
  if (ind2 >= exp2.length) {
    return isStringAllWildcard(exp1.substring(ind1));
  }
  const char1 = ind1 < exp1.length ? exp1[ind1] : '';
  const char2 = ind2 < exp2.length ? exp2[ind2] : '';
  if (char1 === '%' && char2 === '%') {
    return (
      hasOverlap(exp1, exp2, ind1 + 1, ind2 + 1) ||
      hasOverlap(exp1, exp2, ind1, ind2 + 1) ||
      hasOverlap(exp1, exp2, ind1 + 1, ind2)
    );
  } else if (char1 === '%') {
    return hasOverlap(exp1, exp2, ind1, ind2 + 1) || hasOverlap(exp1, exp2, ind1 + 1, ind2);
  } else if (char2 === '%') {
    return hasOverlap(exp1, exp2, ind1, ind2 + 1) || hasOverlap(exp1, exp2, ind1 + 1, ind2);
  }

  return char1 === char2 || char1 === '_' || char2 === '_' ? hasOverlap(exp1, exp2, ind1 + 1, ind2 + 1) : false;
}

function containsNoAlphabetical(s: string): boolean {
  return !/[a-zA-Z]/.test(s);
}

function isStringAllWildcard(s: string): boolean {
  return s.split('').every(c => c === '%');
}

function notLikeAndLike(subQuery: string, query: string): boolean {
  return (
    (subQuery.length > 0 && isStringAllWildcard(subQuery)) ||
    (query.length > 0 && isStringAllWildcard(query)) ||
    (matchesAllNonEmptyStrings(subQuery) && query.length === 0)
  );
}

// Returns true if the pattern matches all non-empty strings. A pattern of this case would be comprised of at least one
// '%', exactly one '_', and contain no other characters.
function matchesAllNonEmptyStrings(pattern: string): boolean {
  let seenPercent = false;
  let seenUnderscore = false;
  for (const c of pattern) {
    switch (c) {
      case '%':
        seenPercent = true;
        break;
      case '_':
        if (seenUnderscore) {
          return false;
        }
        seenUnderscore = true;
        break;
      default:
        return false;
    }
  }

  return seenPercent && seenUnderscore;
}

export class QueryContext<T extends DocumentData = any> {
  /** @internal. */
  private readonly parsedConditions: ContextConditions<T>;

  /** @internal. */
  constructor(readonly query: Query<T>) {
    this.query = query;
    this.parsedConditions = this.parseConditions(this.query.conditions.filter(isSimpleCondition) as GeneralConditions);
  }

  /**
   * The ID of the integration being queried.
   */
  get integrationId(): IntegrationId {
    return this.query.integrationId;
  }

  /**
   * The name of the collection being queried.
   */
  get collectionName(): CollectionName {
    return this.query.collectionName;
  }

  /**
   * The query limit if one exists, -1 otherwise.
   */
  get limit(): number {
    return this.query.limit;
  }

  /**
   * Verifies that the query's sort order aligns with the provided field sorts. The fields specified in the `sorts`
   * parameter must appear in the exact order at the beginning of the query's sort sequence. The query can include
   * additional fields in its sort order, but only after the specified sorts.
   *
   * @param sorts An array of field sorts.
   * @returns Whether the query's sorts matches the provided field sorts.
   */
  sortedBy(sorts: Array<PartialBy<FieldSort<T>, 'asc'>>): boolean {
    const mismatch = sorts.find((fieldSort, index) => {
      return !isEqual(this.query.sortOrder[index], { ...fieldSort, asc: fieldSort.asc ?? true });
    });
    return !mismatch;
  }

  /**
   * Verifies that the query's sort order exactly matches the provided field sorts. The fields specified in the
   * `sorts` parameter must appear in the exact order in the query's sort sequence. No additional sorts may be present
   * in the query.
   *
   * @param sorts An array of field sorts.
   * @returns Whether the query's sorts exactly match the provided field sorts.
   */
  sortedByExact(sorts: Array<PartialBy<FieldSort<T>, 'asc'>>): boolean {
    if (sorts.length !== this.query.sortOrder.length) return false;
    return this.sortedBy(sorts);
  }

  // Subquery
  /**
   * Verifies that the query is a subquery of the specified condition. A subquery is defined as a query that evaluates
   * to a subset of the results that would be obtained by applying the parent condition. The subquery may also include
   * additional conditions, as these only narrow the result set.
   *
   * @param fieldName The name of the field for the condition.
   * @param operator The operator of the condition.
   * @param value The value of the condition.
   * @returns Whether the query is a subquery of the parent condition.
   */
  isSubqueryOf<F extends Paths<T>, O extends AllOperators>(
    fieldName: F,
    operator: O,
    value: GenericValue<T, F, O> | null,
  ): boolean {
    return this.isSubqueryOfCondition({
      fieldName,
      operator,
      value,
    });
  }

  /**
   * Verifies that the query is a subquery of the specified condition. A subquery is defined as a query that evaluates
   * to a subset of the results that would be obtained by applying the parent condition. The subquery may also include
   * additional conditions, as these only narrow the result set.
   *
   * @param condition The condition to validate.
   * @returns Whether the query is a subquery of the parent condition.
   */
  isSubqueryOfCondition(condition: GeneralCondition<T>): boolean {
    const conditions: ContextConditions<T> = this.parsedConditions.filter(c => c.fieldName === condition.fieldName);
    return !!conditions.find(c => this.evaluateSubset(c, condition));
  }

  /**
   * Verifies that the query is a subquery of the specified conditions. A subquery is defined as a query that evaluates
   * to a subset of the results that would be obtained by applying the parent conditions. The subquery may also include
   * additional conditions, as these only narrow the result set.
   *
   * @param conditions The conditions to validate.
   * @returns Whether the query includes subquery of the parent conditions.
   */
  isSubqueryOfConditions(conditions: GeneralConditions<T>): boolean {
    const parsedConditions = this.parseConditions(conditions);
    return parsedConditions.every(c => this.isSubqueryOfCondition(c));
  }

  /**
   * Verifies that the query is a subquery of the specified query. A subquery is defined as a query that evaluates
   * to a subset of the results that obtained for the parent query, including sorts and limits.
   *
   * @param query The query to validate.
   * @returns Whether the query is a subquery of the parent query.
   */
  isSubqueryOfQuery(query: Query<T>): boolean {
    if (query.collectionName !== this.collectionName || query.integrationId !== this.integrationId) return false;
    const simpleConditions = query.conditions.filter(isSimpleCondition);
    const subsetOfConditions = this.isSubqueryOfConditions(simpleConditions as GeneralConditions<T>);
    const subsetOfOrder = this.sortedBy(query.sortOrder);
    const withinLimit = query.limit === -1 || (this.limit > -1 && this.limit < query.limit);
    return subsetOfConditions && subsetOfOrder && withinLimit;
  }

  /**
   * Returns all conditions that apply to any of the specified field names. This method
   * provides a convenient way to retrieve all conditions that involve a specific set of fields.
   *
   * @param fieldNames The field names for which to retrieve conditions.
   * @returns An array of conditions that involve any of the specified field names.
   */
  getConditionsFor<K extends Paths<T>>(...fieldNames: Array<K>): ContextConditions<T, K> {
    return this.parsedConditions.filter(cond => fieldNames.includes(cond.fieldName as any)) as ContextConditions<T, K>;
  }

  /**
   * Returns all conditions that apply to the specified field name. This method provides
   * a convenient way to retrieve all conditions that involve a specific field.
   *
   * @param fieldName The field name for which to retrieve conditions.
   * @returns An array of conditions that involve the specified field name.
   */
  getConditionsForField<K extends Paths<T>>(fieldName: K): ContextConditions<T> {
    return this.parsedConditions.filter(cond => cond.fieldName === fieldName);
  }

  /**
   * Returns true if the given document can be a result of the query.
   * The method does not account for limit and sort order.
   */
  documentMatchesQuery(doc: DocumentData): boolean {
    for (const contextCondition of this.parsedConditions) {
      const fieldNameOrPath = contextCondition.fieldName;
      const operator = contextCondition.operator;

      const valueInDoc = getInPath(doc, fieldNameOrPath);
      if (operator === 'in') {
        if (contextCondition.value.includes(valueInDoc)) {
          continue;
        }
        return false;
      } else if (operator === 'not in') {
        if (contextCondition.value.includes(valueInDoc)) {
          return false;
        }
        continue;
      }

      if (!compareOperator((contextCondition as OtherContextCondition).value, valueInDoc, operator as Operator)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Compares a condition against a given operator and value to determine if the
   * provided condition is a subset of the operator and value. A condition is
   * considered a subset if all values that satisfy (return true for) the
   * condition also satisfy the operator and value.
   *
   * This is done using the underlying CompareTable, which provides a comparison
   * function for each operator pair, or undefined if the comparison would
   * always be false, regardless of the values.
   *
   * @internal.
   */
  private evaluateSubset(queryCondition: ContextCondition<T>, testCondition: GeneralCondition<T>): boolean {
    const { operator: queryOperator, value: queryValue } = queryCondition;
    const { operator, value } = this.parseConditions([testCondition])[0];

    const compareFunction = CompareTable[`${queryOperator}:${operator}`];
    if (!compareFunction) return false;

    return compareFunction(queryValue, value);
  }

  /** @internal. */
  private parseConditions(conditions: Array<GeneralCondition>): ContextConditions<T> {
    const parsedConditions: ContextConditions<T> = [];
    const inMap: Map<FieldName<T>, Array<FieldType>> = new Map();
    const notInMap: Map<FieldName<T>, Array<FieldType>> = new Map();

    conditions.forEach(c => {
      switch (c.operator) {
        case '==':
        case 'in':
          inMap.set(c.fieldName, (inMap.get(c.fieldName) || []).concat(c.value));
          break;
        case '!=':
        case 'not in':
          notInMap.set(c.fieldName, (notInMap.get(c.fieldName) || []).concat(c.value));
          break;
        default:
          parsedConditions.push(c as any as ContextCondition<T>);
          break;
      }
    });

    inMap.forEach((value, fieldName) => {
      parsedConditions.push({
        fieldName: fieldName as any,
        operator: 'in',
        value: value as any,
      });
    });

    notInMap.forEach((value, fieldName) => {
      parsedConditions.push({
        fieldName: fieldName as any,
        operator: 'not in',
        value: value as any,
      });
    });

    return parsedConditions;
  }
}

/** A list of context conditions */
export type ContextConditions<Doc extends DocumentData = any, F extends Paths<Doc> = Paths<Doc>> = Array<
  ContextCondition<Doc, F>
>;

/** A Context condition - a condition that replaces multiple '==' or '!=' conditions with 'in' and 'not in'. */
export type ContextCondition<Doc extends DocumentData = any, F extends Paths<Doc> = Paths<Doc>> =
  | InContextCondition<Doc, F>
  | NotInContextCondition<Doc, F>
  | OtherContextCondition<Doc, F>;

export interface InContextCondition<Doc extends DocumentData = any, F extends Paths<Doc> = Paths<Doc>>
  extends SimpleCondition<Doc, F, 'in'> {
  operator: 'in';
  value: Array<FieldOf<DeepRecord<Doc>, Paths<Doc>> | any>;
}

export interface NotInContextCondition<Doc extends DocumentData = any, F extends Paths<Doc> = Paths<Doc>>
  extends SimpleCondition<Doc, F, 'not in'> {
  operator: 'not in';
  value: Array<FieldOf<DeepRecord<Doc>, Paths<Doc>> | any>;
}

export interface OtherContextCondition<Doc extends DocumentData = any, F extends Paths<Doc> = Paths<Doc>>
  extends SimpleCondition<Doc, F, Exclude<ContextOperator, 'in' | 'not in'>> {
  operator: Exclude<ContextOperator, 'in' | 'not in'>;
  value: FieldOf<DeepRecord<Doc>, Paths<Doc>> | any;
}

/** A condition that includes the 'in' and 'not in' operators. */
export interface GeneralCondition<Doc extends DocumentData = any, F extends Paths<Doc> = Paths<Doc>>
  extends SimpleCondition<Doc, F, AllOperators> {
  operator: AllOperators;
  value: any;
}

/** A list of general conditions. */
export type GeneralConditions<Doc extends DocumentData = any, F extends Paths<Doc> = Paths<Doc>> = Array<
  GeneralCondition<Doc, F>
>;

export type ContextOperator = Exclude<Operator, '==' | '!='> | 'in' | 'not in';
type AllOperators = Operator | 'in' | 'not in';

/** A generic value that can exist in a query. */
export type GenericValue<Doc = any, F extends Paths<Doc> = Paths<Doc>, O extends AllOperators = any> = O extends 'in'
  ? Array<DeepRecord<Doc>[F]> | null
  : O extends 'not in'
    ? Array<DeepRecord<Doc>[F]> | null
    : DeepRecord<Doc>[F] | null;
