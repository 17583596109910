<div class="onboarding_content">
  <div class="onboarding_entry" *ngIf="stepContent">
    <h1 class="header">{{ stepContent.title }}</h1>
    <onboarding-step />
    <div class="form">
      @for (key of entrySteps; track key) {
        <magic-form [data]="stepContent.form"></magic-form>
      }
    </div>
  </div>
</div>
